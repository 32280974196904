@import "../../assets/theme";
@import "../../assets/defaultStyles.scss";

.conatiner {
    @extend .wrapper_container_1400px;
    margin-bottom: 96px;
    .title {
        font-family: Bower;
        margin: 90px 0 40px 0;
        line-height: 1.2;
        @extend .font_size_68_respose;
    }
    .description {
        font-family: Arial, Helvetica, sans-serif;
        margin: 40px 0;
        line-height: 1.2;
        @extend .font_size_30_respose;
    }
    .content {
        font-family: Arial, Helvetica, sans-serif;
        margin: 40px 0;
        line-height: 1.5;
        @extend .font_size_20_respose;
        a {
            color: black;
            text-decoration: none;
            border-bottom: 2px solid transparent;
            border-color: $blue;
            &:hover {
                border-top: 2px solid transparent;
                color: $white;
                background: $blue;
            }
        }
    }
}