@import "./theme";

.wrapper_container_1400px {
    max-width: 1400px;
    margin: auto;
    padding: 0 96px;
    @media (max-width: $LG) {
      padding: 0px 62px;
    }
    @media (max-width: $MD) {
        padding: 0px 40px;
    }
    @media (max-width: $SM) {
        padding: 0px 20px;
    }
} 
.font_size_68_respose {
    font-size: 68px;
    @media (max-width: $MD) {
        font-size: 42px;
    }
}
.font_size_52_respose {
    font-size: 52px;
    @media (max-width: $MD) {
        font-size: 32px;
    }
}
.font_size_38_respose {
    font-size: 38px;
    @media (max-width: $MD) {
        font-size: 24px;
    }
}
.font_size_30_respose {
    font-size: 30px;
    @media (max-width: $MD) {
        font-size: 24px;
    }
}
.font_size_28_respose {
    font-size: 28px;
    @media (max-width: $MD) {
        font-size: 22px;
    }
}
.font_size_20_respose {
    font-size: 20px;
    @media (max-width: $MD) {
        font-size: 16px;
    }
}
.font_size_18_respose {
    font-size: 18px;
    @media (max-width: $MD) {
        font-size: 16px;
    }
}