@import "../../assets/theme.scss";
@import "../../assets/defaultStyles.scss";

.container{
  width: 100%;
  font-family: Arial;
  box-sizing: border-box;
  margin: 0 auto;
}

.slide{
  position: relative;
  min-height: 600px;
  @media (max-width: $LG) {
    min-height: 480px;
  }
  @media (max-width: $MD) {
    min-height: 420px;
  }
}

.bg_img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1
}

.content{
  display: flex;
  flex-direction: column;
  @extend .wrapper_container_1400px;
  @media (max-width: $SM) {
  }
}

.text_container_respose {
  max-width: 66%;
  @media (max-width: $SM) {
    max-width: 100%;
  }
  @media (max-width: $XS) {
    max-width: 100%;
  }
}

.name{
  margin-top: 96px;
  color: $black;
  font-weight: bold;
  font-family: Bower;
  line-height: 1.1;
  overflow: hidden;
  text-overflow: ellipsis;
  @extend .font_size_68_respose;
  @extend .text_container_respose;
  @media (max-width: $LG) {
    margin-top: 62px;
  }
  @media (max-width: $MD) {
    margin-top: 48px;
  }
  @media (max-width: $SM) {
    margin-top: 42px;
  }
  @media (max-width: $XS) {
    margin-top: 42px;
  }
}

.description{
  color: #000000;
  @extend .font_size_20_respose;
  @extend .text_container_respose;
  line-height: 30px;
  margin: 32px 0 48px 0;
  font-family: Arial, Helvetica, sans-serif;
  @media (max-width: $MD) {
    margin: 20px 0 32px 0;
  }
}

.read_more_cnt{
  display: flex;
  align-items: center;
  margin: 28px 0px;
  cursor: pointer;

  &:hover{
    .arrow_cnt {
      transform: translateX(10px);
    }
    .read_more{
      color: $blue;
    }
  }
}

.arrow_cnt{
  display: block;
  height: 24px;
  margin-left: 12px;
  transition: transform 400ms;
}

@media (max-width: $SM) {
  .bg_img{
    position: relative;
    width: 100%;
    height: 450px;
  }

  .content{
    width: 100%;
    min-height: initial;
    margin: 20px 0 0 0;
  }
}
