@import "../../assets/theme";
.btn {
  min-width: 180px;
  min-height: 48px;
  background: $white;
  transition: all 120ms linear;
  padding: 12px 20px 12px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: $blue;
  border: 2px solid $blue;
  box-sizing: border-box;
  cursor: pointer;
  &:disabled {
    background: $grey;
    border: none;
    color: $white;
    &:hover {
      transform: none;
      background-color: $grey;
      box-shadow: none;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $blue;
    color: $white;
    box-shadow: 0 7px 14px 0 rgba(0,0,0,0.2);
    transform: translateY(-2px);
  }
}
