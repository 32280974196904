@import "../../assets/theme.scss";
@import "../../assets/defaultStyles.scss";
.wrapper {
    border-top: 1px solid $black;
}
.inner_wrapper {
    @extend .wrapper_container_1400px;
}
.logo {
    margin: 96px 0 60px 0;
    @media (max-width: $MD) {
        margin: 60px 0 ;
    }
    @media (max-width: $SM) {
        margin: 40px 0;
    }
}
.list {
    display: flex;
    list-style: none;
    li {
        margin-right: 20px;
    }
}
.links {   
    text-decoration: none;
    color: $dark_gray;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.copyrights-wrapper {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 16px;    
    color: $dark_gray;
    font-family: ".SF NS Display";
    padding: 20px 0 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid $dark_gray;
    @media (max-width: $SM) {
        flex-direction: column;
    }
}
.links:hover {
    color: $blue;
    border-bottom: 2px solid transparent;
    border-color: $blue;
}

@media screen and (max-width: $SM) {
    .list {
        flex-direction: column;
        li {
            margin: 0 0 20px 0;
        }
    }
}