@import "../../assets/theme";
@import "../../assets/defaultStyles.scss";
.container {
  margin: 0 0 20px 0;
  padding: 0;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
  &:hover {
    .name_container {
      color: $blue;
    }
    .icon_container {
      transform: translate(16px, 0px)
    }
  }
}
.name_container {
  @extend .font_size_18_respose;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.icon_container {
  margin: 0px 16px;
  display: flex;
  transition: transform 500ms
}
