@import "../../assets/theme";
.relative_articles_title {
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    padding: 28px 0;
    border-top: 1px solid $black;
    margin-top: 40px;
}
.relative_articles_container {
    margin: -20px -20px 20px -20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .relative_article {
        padding: 20px;
        width: 33%;
        box-sizing: border-box;
        @media (max-width: $SM) {
            width: 100%
        }
    }
}