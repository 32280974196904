@import "../../assets/theme";
.container {
  display: flex;
  align-items: center;
}
.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
}
.hided_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.masked_input {
  min-height: 28px;
  min-width: 28px;
  border-radius: 50%;
  border: 1px solid $grey;
  background: $white;
  position: relative;
}
.text_container {
  margin: 0px 16px;
}
.checked {
  border: 1px solid $blue;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 16px;
    width: 16px;
    transform: translate(-50%, -50%);
    background: $blue;
    border-radius: 50%;
  }
}