@import "../../assets/theme";
@import "../../assets/defaultStyles.scss";
.content {
  padding-bottom: 96px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @extend .font_size_38_respose;
  font-family: Bower;	
  font-weight: bold;
  @media (max-width: $MD) {
    padding-bottom: 60px;
}
@media (max-width: $SM) {
  padding-bottom: 40px;
}	
}
.description {
  text-align: center;
  font-size: 16px;
  padding-top: 96px;
  color: $black;	
  font-family: Arial;	
  font-weight: bold;
  line-height: 20px;
  position: relative;
  border-top: 1px solid $gray;	
  @media (max-width: $MD) {
    padding-top: 60px;
}
@media (max-width: $SM) {
  padding-top: 40px;
}	
}
.name {
  text-align: center;
  @extend .font_size_52_respose;	
  color: $black;	
  font-family: Bower;	
  font-weight: bold;	
  padding-top: 32px
}