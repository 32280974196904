@import "../../assets/theme.scss";
@import "../../assets/defaultStyles.scss";


.menu_cover {
  width: 26px;
  height: 26px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: none;

  @media screen and (max-width: $MD) {
    display: flex;
  }

  &:focus {
    outline: none;
  }
}

.hamburger {
  width: inherit;
  height: 4px;
  position: absolute;
  background-color: $darkBlue;
  transition: all .5s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    width: 100%;
    height: 4px;
    background-color: $darkBlue;
    transition: all .5s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    width: 100%;
    height: 4px;
    position: absolute;
    background-color: $darkBlue;
    transition: all .5s ease-in-out;
  }
}

.is_active {
  background-color: transparent;

  &:before {
    top: 0;
    transform: rotate(45deg);
    background-color: $white;
  }

  &:after {
    top: 0;
    transform: rotate(135deg);
    background-color: $white;
  }
}

.wrapper {
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  background: $white;
  outline: none;
  user-select: none;
  position: relative;
  transition: background-color 500ms;
  border-bottom: 1px solid $gray;

  & *{
    outline: none;
    font-family: Arial;
  }

  .inner_wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    @extend .wrapper_container_1400px;

    .img_cnt {
      display: flex;
      margin-right: 56px;
      align-items: center;
    }

    .preview_container {
      position: absolute;
      background: $green;
      top: 0px;
      right: 96px;
      padding: 8px 12px;
      color: $white;

      @media (max-width: $MD) {
        right: 40px;
      }

      @media (max-width: $SM) {
        right: 20px;
      }
    }

    .list_container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 20px;
      box-sizing: border-box;
      padding-bottom: 12px;

      @media (max-width: $MD) {
        padding-top: 0;
      }

      .list_label{
        font-size: 12px;
        font-weight: bold;
        margin-left: 10px;
        width: 100%;
        padding-bottom: 12px;

        @media (max-width: $MD) {
          margin-left: 0;
          opacity: 0.7;
        }
  
      }

      .list_cnt {
        display: flex;
        flex-wrap: wrap;
        background-color: $white;
        transition: all 500ms;

        .active{
          .item {
            &:after {
              width: 100%;
            }
          }
        }

        .item {
          display: flex;
          align-items: center;
          min-width: 0;
          border: none;
          padding: 0;
          margin: 0 10px;
          outline: none;
          cursor: pointer;
          position: relative;
          background: none;
          font-size: 16px;
          line-height: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-bottom: 8px;

          &:focus {
            outline: none;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: -11px;
            left: 0;
            width: 0;
            height: 4px;
            background-color: $blue;

            @media (max-width: $MD) {
              display: none;
            }
          }

          &:hover{
            &:after {
              width: 100%;
            }
            color: $blue;
          }
          .inner_item {
            position: relative;
          }
        }
      }

      @media screen and (max-width: $MD) {
        position: absolute;
        overflow: hidden;
        height: 0;
        top: 100%;
        width: 100%;
        left: 0;
        color: $white;

        .list_cnt {
          padding: 40px;
          width: 100%;
          height: auto;
          transform: translate(0, -100%);
          flex-direction: column;
          @media screen and (max-width: $SM) {
            padding: 20px;
          }

          .item {
            height: auto;
            margin: 0;

            &:hover{
              &:after {
                display: none;
              }
              color: $white;
            }

            .inner_item {
              height: 60px;
              display: flex;
              align-items: center;

              .list_label {
                  opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  &.is_opened{
    @media screen and (max-width: $MD) {
      background: $darkBlue;

      .list_container {
        height: auto;
        overflow: auto;
        max-height: 700px;
        
        .list_cnt {
          transform: translate(0, 0);
          background: $darkBlue;
        }
      }
    }
  }
}