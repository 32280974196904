@import "../../assets/theme";
.inner_container {
    width: 100%;
    padding-top: 60%;
    position: relative;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
}
.title_container {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: 20px 0;
    font-size: 24px;
    line-height: 1.2;
  }
  .description_container {
    font-family: Arial, Helvetica, sans-serif;
    margin: 20px 0;
    font-size: 18px;
    line-height: 1.5;
}
 

    
   