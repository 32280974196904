@import "../../assets/theme";
@import "../../assets/defaultStyles.scss";

.card_container {
  display: flex;
  flex-direction: row;
  @media (max-width: $SM) {
    flex-direction: column
  }
  .image_container {
    width: 50%;
    @media (max-width: $SM) {
      width: 100%;
    }
    .inner_img_container {
      width: 100%;
      padding-top: 60%;
      position: relative;
      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

  }
  .main_description_container {
    padding: 0px 40px;
    width: 50%;
    @media (max-width: $SM) {
      width: 100%;
      padding: 0;
    }
    .title_container {
      @extend .font_size_38_respose;
      line-height: 1.2;
      font-weight: bold;
      margin: 20px 0;
      @media (max-width: $SM) {
        font-family: Arial, Helvetica, sans-serif;
      }
    }
    .description_container {
      @extend .font_size_20_respose;
      line-height: 1.5;
      margin: 20px 0;
      font-family: Arial, Helvetica, sans-serif;
    }
    .links_container {
      display: flex;
      flex-direction: row;
      margin: 20px 0;
      @media (max-width: $SM) {
        flex-direction: column
      }
      .links_item_container {
        width: 50%;
        display: flex;
        flex-direction: column;
        @media (max-width: $SM) {
          width: 100%
        }
      }
    }
  }
  &.sm {
    flex-direction: column;
    .image_container {
      width: 100%;
    }
    .main_description_container {
      width: 100%;
      padding: 0;
      .title_container {
        font-size: 24px;
        font-family: Arial, Helvetica, sans-serif;
      }
      .description_container {
        font-size: 18px;
      }
      .links_container {
        flex-direction: column;
        .links_item_container {
          width: 100%
        }
      }
    }
  }
}