@import "../../assets/theme.scss";
.input_container {
  width: 100%;
  padding: 14px;
  outline: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.input {
  font-size: 16px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  resize: none;
  &:focus {
    outline: none;
  }
}
.default_input_styles {
  border: 1px solid $grey;
}
.error_input_container {
  border: 1px solid $red;
}
.selected_input_container {
  border: 1px solid $blue;
}
.error_text {
  color: $red;
}
.label_style {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;
}
.helper_text {
  font-size: 12px;
}
