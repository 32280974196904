$XS: 480px;
$SM: 768px;
$MD: 960px;
$LG: 1280px;
$XL: 1920px;

$white: #fff;
$black: #000;
$dark_gray: #4D4D4D;
$blue: #1F40E6;
$darkBlue: #051C2C;
$grey: #B3B3B3;
$green: #77bb51;
$gray: #D0D0D0;
$red: red;
$modal_bg: grey;
$gold:#ffd048;