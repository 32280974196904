@import "../../assets/theme";
.main_container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  .footer_container {
    flex: 0 0 auto;
  }
  .body_container {
    flex: 1 0 auto;
    margin: 80px 0 0 0;
  }
  .header_container {
    top: 0;
    left: 0;
    right: 0;
    background: $white;
    position: fixed;
    z-index: 10;
  }
}