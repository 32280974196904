@import "../../assets/theme.scss";
@import "../../assets/defaultStyles.scss";

.dots_container {
  @extend .wrapper_container_1400px;
  position: absolute;
  top: 550px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  @media (max-width: $LG) {
    top: 450px;
  }
  @media (max-width: $MD) {
    top: 380px;
  }
  @media (max-width: $SM) {
    top: 380px;
  }
  display: flex;
  .dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .default_dot {
    background: $grey;
  }
  .selected_dot {
    background: $blue;
  }
}


