@import "../../assets/theme";
@import "../../assets/defaultStyles.scss";
.container {
  background: $white;
  margin: 48px 0 28px;
  font-family: Arial, Helvetica, sans-serif
}

.title {
  @extend .font_size_52_respose;
  font-weight: bold;		
  text-align: center;
  font-family: 'Bower';
}

.sub_title {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;
  .option {
    font-weight: normal;
    color: $grey;
  }
}

.additional_info {
  font-size: 15px;
  line-height: 30px;
}

.radio_group_container {
  margin: 16px 0px
}

.company_info {
  font-size: 10px;
  line-height: 20px;
  color: $grey;
  margin: 28px 0px;
}

.item {
  margin: 28px 0px;
}

.footer_cnt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $SM) {
    flex-direction: column;
  }
}

.error_cnt{
  position: relative;
  background-color: $gold;
  flex-grow: 1;
  margin: 0 0 0 20px;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  box-sizing: border-box;
  min-width: 0;
  @media (max-width: $SM) {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.err_text{
  font-size: 16px;
  line-height: 17px;
  margin: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hide_err{
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

.success_text{
  height: 200px;
  text-align: center;
  margin-top: 20px;
}

