@font-face {
    font-family: 'Bower';
    font-style: normal;
    font-weight: 800;
    src:url('./fonts/Bower-Bold.woff2') format('woff2'),
        url('./fonts/Bower-Bold.woff') format('woff'),
        url('./fonts/Bower-Bold.ttf') format('truetype');
  } 

* {
    margin: 0;
    padding: 0;
  }

#app {
    height: 100%;
    background-color: red;
}

body {
    font-family: Bower;
}