@import "../../assets/theme.scss";
@import "../../assets/defaultStyles.scss";

.wrapper {
    align-content: center;
}

.head_image {
    object-fit: cover;
    background-color: $white;
    width: 100%;
    height: 500px;
}

.inner_wraper {
    padding: 0 96px;
    @media (max-width: $MD) {
        padding: 0px 40px;
    }
    @media (max-width: $SM) {
        padding: 0;
    }
}
.text_wrapper {
    text-align: left;
    position: relative;
    top: -116px;
    background-color: $white;
    max-width: 1040px;
    margin: auto;
    padding: 60px 96px 0px 96px;
    @media (max-width: $MD) {
        top: -60px;
        padding: 40px 40px 0px 40px;
    }
    @media (max-width: $SM) {
        top: 0;
        padding: 20px 20px 0px 20px;
    }
}

.link {
    outline: 0;
    color: $blue;		
    font-family: Arial;	
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    .arrow{
      display: flex;
      transform: rotate(180deg);
      margin-right: 12px;
    }
}

.link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.header {
    font-weight: bold;
    @extend .font_size_68_respose;
}

.description {
    font-family: Arial;	
    @extend .font_size_30_respose;
    margin: 60px 0 20px 0;
    @media (max-width: $MD) {
        margin: 40px 0 20px 0px
    }
}

.content {
    margin-top: 36px;

    :global(.color_blue) {
      color:blue;
    }

    p {
        font-family: Arial;	
        @extend .font_size_20_respose;
        color: $black;
        margin: 20px 0;
        line-height: 1.5;
        white-space: pre-wrap;
    }

    sup{
      font-size: .62em;
      vertical-align: baseline;
      position: relative;
      top: -0.76em;
      display: inline-block;
    }

    sub{
      font-size: .62em;
      vertical-align: baseline;
      position: relative;
      bottom: -0.28em;
      display: inline-block;
    }
    
    blockquote {
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        margin: 20px 0;
        padding: 28px 0;
        margin-left: 0;
        font-family: Bower;	
        text-align: center;
        @extend .font_size_28_respose;
        font-weight: bold;
    }

    h1, h2, h3 {
        font-family: Arial;	
        @extend .font_size_30_respose;	
        font-weight: bold;
        line-height: 1.2;
        margin: 24px 0;
        
    }

    ul, ol {
        list-style-type: none;
        font-family: Arial;	
        @extend .font_size_20_respose;
        line-height: 1.5;
        margin: 20px 0 20px 40px;
    }

    ol {
        counter-reset: li;
    }

    ol li::before {
        content: counter(li);
        counter-increment: li
    }

    ul li::before {
        content: "•";
    }

    li::before { 
        color: $blue;
        display: inline-block; 
        width: 1em;
        margin-left: -1em
    }

    a {
        color: black;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        border-color: $blue;
        &:hover {
            border-top: 2px solid transparent;
            color: $white;
            background: $blue;
        }
    }

  figure {
      margin: 20px 0 20px 0;
      text-align: center;

      img {
          width: 100%;
          height: auto;
      }

      div {
          width: 100%;
          padding-top: 60%;
          position: relative;

          iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
          }
      }
  }
    
}

