@import "../../assets/theme";
@import "../../assets/defaultStyles.scss";
.content_container {
    @extend .wrapper_container_1400px;
}
.separator {
    border-top: 1px solid $gray;
    padding-top: 96px;
    @media (max-width: $MD) {
        padding-top: 60px;
    }
    @media (max-width: $SM) {
        padding-top: 40px;
    }
}
.category_conteiner {
    padding: 96px 0px;
    @media (max-width: $MD) {
        margin: 60px 0px;
    }
    @media (max-width: $SM) {
        margin: 40px 0px;
    }
}
.category_conteiner_inner {
    padding-left: 96px;
    padding-right: 96px;
    @media (max-width: $MD) {
        padding: 0px 60px;
    }
    @media (max-width: $SM) {
        padding: 0px;
    }
}
.form_container{
    max-width: 584px;
    margin: auto;
}
.subcategory_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .subcategory_card {
        box-sizing: border-box;
    }
    .small {
        width: 33%;
        padding: 40px 20px 20px 20px;
        @media (max-width: $SM) {
            width: 100%;
            padding: 20px;
        }
    }
    .half_width {
        width: 50%;
        @media (max-width: $SM) {
            width: 100%
        }
    }
    .full_width {
        width: 100%;
    }
}    
