.item_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}